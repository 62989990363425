<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <div class="col-12 col-xl-10 offset-xl-1">
        <!-- slider -->
          <!--<section class="row" :style="{ display: showBanner ? 'block' : 'none' }">
          <div class="col-12 banner-col-12" style='text-align: center'>
            <img src='/img/long_banner_1000x50.png' style='width: 100%; max-width: 1024px;' alt='실습으로 배우는 인공지능 책 배너' class="img-hover"/>
            <div class='row btn-row'>
              <div class='col-3'>
                <a @click='toggleShowBanner("https://www.yes24.com/Product/Goods/124939603")' class="hero__btn cursor-pointer" style='width: 100%;'>예스24</a>
              </div>
              <div class='col-3'>
                <a @click='toggleShowBanner("https://www.aladin.co.kr/shop/wproduct.aspx?ISBN=K612938419")' class="hero__btn cursor-pointer" style='width: 100%;'>알라딘</a>
              </div>
              <div class='col-3'>
                <a @click='toggleShowBanner("https://product.kyobobook.co.kr/detail/S000212270471")' class="hero__btn cursor-pointer" style='width: 100%;'>교보문고</a>
              </div>
              <div class='col-3'>
                <a @click='toggleShowBanner("https://www.ypbooks.co.kr/book.yp?bookcd=101279367")' class="hero__btn cursor-pointer" style='width: 100%;'>영풍문고</a>
              </div>
            </div>
            <a @click='toggleShowBanner()' style='float: right; cursor: pointer; position: absolute'><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" x="0" y="0" viewBox="0 0 329.269 329" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0" fill="#000000" opacity="1" data-original="#000000" style='fill: rgb(37, 165, 106)'></path></g></svg></a>
          </div>
        </section>-->
        <!-- end slider -->

        <!-- breadcrumb -->
        <ul class="breadcrumb" v-if='selectedModule'>
          <li class="breadcrumb__item"><router-link to="/">홈</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">{{selectedModule.title}}</li>
        </ul>
        <!-- end breadcrumb -->

        <div class="article" v-if='selectedModule'>
          <!-- article content -->
          <div class="article__content">
            <img v-if="$route.params.name !== ''" :src="'/img/preview/' + $route.params.name + '.jpg'" :alt="$route.params.title">
            <img v-else src="/img/posts/1.jpg">

            <div class="article__meta">
              <a class="article__category" :class="{'article__category_hidden': !selectedModule.webcam}" aria-label="웹캠이 필요합니다." data-balloon-pos="right"><img class='article__category__image' src="/icon/webcam.svg"/></a>
              <span class="article__date">
                <img class='article__date__image' src="/icon/bookmark.svg"/> {{selectedModule.bookChapter}}
                <img class='article__date__image' src="/icon/page.svg"/> {{selectedModule.bookPage}}
              </span>
            </div>

            <h3>{{selectedModule.title}}</h3>
            <p>{{selectedModule.description}}</p>
            <!-- end article content -->

            <!-- share -->
            <a class="share d-flex align-items-center my-3" style='flex-wrap: inherit; cursor: default' v-if="selectedModule.howTo && selectedModule.howTo !== ''">
              <span class="share__link m-0" style='min-width: 58px'>방법</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.howTo}}</span>
            </a>
            <a class="share d-flex align-items-center my-3" :href="selectedModule.source" target="_blank" style='overflow: hidden; white-space: nowrap; text-overflow: ellipsis; flex-wrap: nowrap'>
              <span class="share__link m-0">출처</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.source}}</span>
            </a>
            <a class="share d-flex align-items-center my-3" @click='showLicense(selectedModule.license)' style='overflow: hidden; white-space: nowrap; text-overflow: ellipsis; flex-wrap: nowrap; cursor: pointer'>
              <span class="share__link m-0">라이선스</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.license === 'None' ? '없음' : selectedModule.license === 'External Site' ? '외부 사이트' : selectedModule.license}}</span>
            </a>
            <!-- end share -->

            <!-- comments -->
            <div class="comments mt-3 p-0">
              <div class="comments__form mt-3 p-0" style='border: none'>
                <div class='row'>
                  <div class='col-12 col-sm-6'>
                    <a type="button" class="sign__btn" :href="selectedModule.href" target="_blank">실행하기</a>
                  </div>
                  <div class='col-12 col-sm-6 mt-4 m-sm-0'>
                    <a @click='$router.go(-1)' class="hero__btn">뒤로 가기</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- end comments -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  name: "Article",
  data: function() {
    return {
      modules: store.state.modules,
      selectedModule: null,
      showBanner: true
    };
  },
  created() {
    let name = this.$route.params.name;
    this.selectedModule = _.findWhere(this.modules, {name: name});

    $('html, body').animate({
      scrollTop: 0
    }, 250);

    try {
      let showBanner = sessionStorage.getItem('showBanner');
      if(showBanner && showBanner === 'FALSE'){
        this.showBanner = false;
      }
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
  },
  methods: {
    showLicense(name){
      let url;
      switch (name) {
        case 'Apache License 2.0':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1002';
          break;
        case 'MIT':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1006';
          break;
        case 'Creative Commons Attribution 4.0':
          url = 'https://creativecommons.org/licenses/by/4.0/deed.ko';
          break;
      }

      if(url){
        window.open(url, '_blank');
      }
    },
    toggleShowBanner(url){
      this.showBanner = false;
      sessionStorage.setItem('showBanner', 'FALSE');

      if(url){
        window.open(url, '_blank');
      }
    }
  },
}
</script>
